/** @jsx jsx */
import { jsx } from 'theme-ui'
import { graphql, Link } from "gatsby"
import { RichText } from 'prismic-reactjs'
import { Box, Flex } from 'rebass'
import { MdChevronRight } from "react-icons/md"
import { uniqueId } from 'lodash'

import { Container, Layout, Section } from '@giraldomac/gatsby-theme-mmdbase/src/components/layout'
import { SEO } from '@giraldomac/gatsby-theme-mmdbase/src/components/elements'
import { PubHero, ListItem, Alert } from '../../../components/elements'

const Index = ({ data }) => {

  const page = data.prismic.allEmergency_noticess

  return (
    <Layout>
      <SEO meta_title={page.meta_title} meta_description={page.meta_description} />
      <PubHero title="Notices" />
      <Section >
        <Container>
          <Flex flexWrap="wrap" justifyContent="center">
            <Box width={['full', 4 / 5, 3 / 4]} sx={{
              backgroundColor: 'wrapperbox',
              padding: [4, 5],
            }}>
              <h2>Emergency Notices</h2>
              {page.totalCount > 0 ?
                (<ul sx={{ m: 0 }}>
                  {page.edges.map(({ node: notice }) => (
                    <ListItem key={`${uniqueId('emergency_notice_')}`}>
                      <Link to={`notices/emergencies/${notice._meta.uid}`}>{notice.notice_date} - {RichText.asText(notice.title)} </Link><MdChevronRight />
                    </ListItem>
                  ))}
                </ul>)
                : (<Alert>No Emergency Notices have been posted yet.</Alert>)
              }
            </Box>
          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      allEmergency_noticess {
        totalCount
        edges {
          node {
            content
            meta_description
            meta_title
            title
            _linkType
            _meta { 
            uid
            }
          }
        }
      }
    }
  }
`

export default Index